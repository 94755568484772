// window.Element || (window.Element = {});
//
// Element.SocialIcons = (function() {
//     var PopupCenter;
//
//     function SocialIcons() {
//         this.setSocialClicks();
//         return this;
//     }
//
//     SocialIcons.prototype.setSocialClicks = function() {
//         return $('ul.social-icons li a.popitup').click(function() {
//             PopupCenter(this.href, this.href, '600', '500', {
//                 toolbar: 0,
//                 resizable: 0,
//                 location: 1,
//                 menubar: 0,
//                 status: 1
//             });
//             return false;
//         });
//     };
//
//     PopupCenter = function(url, title, w, h, opts) {
//         var _innerOpts, dualScreenLeft, dualScreenTop, height, left, newWindow, p, top, width;
//         _innerOpts = '';
//         if (opts !== null && typeof opts === 'object') {
//             for (p in opts) {
//                 if (opts.hasOwnProperty(p)) {
//                     _innerOpts += p + '=' + opts[p] + ',';
//                 }
//             }
//         }
//         dualScreenLeft = window.screenLeft !== void 0 ? window.screenLeft : screen.left;
//         dualScreenTop = window.screenTop !== void 0 ? window.screenTop : screen.top;
//         width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
//         height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
//         left = width / 2 - (w / 2) + dualScreenLeft;
//         top = height / 2 - (h / 2) + dualScreenTop;
//         newWindow = window.open(url, title, _innerOpts + ' width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
//         if (window.focus) {
//             newWindow.focus();
//         }
//     };
//
//     return SocialIcons;
//
// })();
const socialShare = (shareLinks) => {
    _.map(shareLinks, link => {
        if (!link.classList.contains('socialSharing__link--email')) {
            link.addEventListener('click', (event)=> {
                event.preventDefault();
                window.open(event.target.href, 'targetWindow', "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + 500 + ",height=" + 500);

            });
        }
    })
};

export default socialShare



/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)

// const imagePath = (name) => images(name, true)
import imageLazyLoad from '../components/imageLazyLoad';
import infiniteScroll from '../components/infiniteScroll';
import masonryInit from '../components/masonryLayout';
import lightbox from "../components/lightbox";
import zoomImage from "../components/zoom";
import modal from "../components/modal";
import accessibility, {buttonLoading} from "../components/accessibility";
import {hideOnScroll, mobileNavigation} from "../components/navigation";
import searchDropdown from "../components/searchDropdown";
import socialShare from "../components/socialShare"
import InitUserFavourites, {GetUserFavourites} from "../components/userSelections";
import { renderNewsletter } from "../components/newsletterForm";
import dataLayer from "../components/dataLayer";
import ModalPopup from "../components/modal";
import imagesLoaded from "imagesloaded";
import {textDropdown, focusDropdown} from "../components/dropdowns";
import Carousel from "../components/carousel";
import readmore from "../components/readmore";
import viewOnWall from "../components/viewOnWall";
import EnquiryForm from "../components/enquiryForm";



window.summerExhibition = {};
window.dataLayer = window.dataLayer || [];
const secondaryNav = document.getElementById("secondaryNavigation");
if (secondaryNav) hideOnScroll(secondaryNav), mobileNavigation(secondaryNav);
renderNewsletter();
if (document.getElementById('userFavourites__page')) new GetUserFavourites();

document.addEventListener("DOMContentLoaded", () => {
    imageLazyLoad();
    dataLayer.initScrape();
    // modal();
    searchDropdown();
    // zoom();

    const imageZoomEl = document.getElementById("imageZoom");
    if (imageZoomEl) {
        new zoomImage(imageZoomEl);
    }
    // Modal
    //---------------------------------

    if (document.querySelectorAll('[data-modal]').length > 0) {
        _.map(document.querySelectorAll('[data-modal]'), item => {
            ModalPopup.init(item);
        });
    }

    // Masonry
    //---------------------------------
    const masonryGroup = document.getElementsByClassName('cardGroup--masonry');
    if (masonryGroup.length > 0) {
        window.summerExhibition.macyLayout = new masonryInit(masonryGroup[0].getElementsByClassName('grid')[0]);
        imagesLoaded(masonryGroup, ()=> {
            window.summerExhibition.macyLayout.layout();
        });
    }

    // Infinite Scroll
    //---------------------------------
    const infiniteScrollItem = document.querySelectorAll("[data-pagination='infiniteScroll']");
    if (infiniteScrollItem.length > 0) {
        window.summerExhibition.infiniteScroll = new infiniteScroll();
    }

    // Lightbox
    //---------------------------------
    const lightboxItemArray = document.querySelectorAll('[data-lightbox-item]');
    const onHomepage = window.location.pathname == '/2019'
    if (lightboxItemArray.length > 0 && !onHomepage) {
        window.summerExhibition.lightbox = new lightbox(lightboxItemArray);
    }

    // Social share
    //---------------------------------
    const socialShareLinks = document.getElementsByClassName('socialSharing__link');
    if (socialShareLinks.length > 0) {
        socialShare(socialShareLinks);
    }

    // User selections
    //---------------------------------
    const isArtworkPage = document.getElementsByClassName('js__addFavouritesArtworkPage').length > 0
    if (isArtworkPage) {
      window.summerExhibition.userFavourites = new InitUserFavourites(document.getElementsByClassName('js__addFavouritesArtworkPage'), true);
    }
    if (!isArtworkPage && document.getElementsByClassName('card--artwork').length > 0) {
      window.summerExhibition.userFavourites = new InitUserFavourites(document.getElementsByClassName('card--artwork'),false);
    }

    // Button loading

    const loadingButtons = document.querySelectorAll('#new_search button[type="submit"]');
    _.map(loadingButtons, button => {
        buttonLoading(button, true)
    });

    // Text dropdown

    const textDropdownItems = document.querySelectorAll('.textDropdown')
    if (textDropdownItems.length > 0) {
        textDropdown(textDropdownItems)
    }

    // Card carousel

    const cardCarouselEls = document.querySelectorAll('.cardGroup--carousel')
    if (cardCarouselEls.length > 0) {
        _.map(cardCarouselEls, el => {
           new Carousel(el)
        })

    }

    // Focus dropdown

    const focusDropdownEls = document.querySelectorAll('.focusDropdown')
    if (focusDropdownEls.length > 0) {
        _.map(focusDropdownEls, el => {
            focusDropdown(el)
        })

    }


    const readmoreBlock = document.querySelector('[data-readmore="block"]');
    if (readmoreBlock) readmore(readmoreBlock)


    const viewOnWallEl = document.querySelector("[data-view-on-wall]");
    if (viewOnWallEl) new viewOnWall(viewOnWallEl);


    const enquiryFormButtonEl = document.querySelector(".enquiry-button");
    if (enquiryFormButtonEl) new EnquiryForm(enquiryFormButtonEl);

        // Accessibilty
    //---------------------------------
    accessibility();
});

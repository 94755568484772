import {reverse} from 'lodash'


export const insertAfterElement = (element, referenceNode) => {
    referenceNode.parentNode.insertBefore(element, referenceNode.nextSibling);
}

export const insertBeforeElement = (element, referenceNode) => {
    referenceNode.parentNode.insertBefore(element, referenceNode);
}
export const insertElementsAtStart = (elements, referenceNode) => {
    return new Promise ((resolve, reject)=> {
       const arrayNodes = Array.from(elements);
        for (let i = arrayNodes.length-1; i >= 0 ; i--) {
            referenceNode.insertBefore(arrayNodes[i], referenceNode.firstChild);
            window.summerExhibition.macyLayout.prepended(arrayNodes[i]);
            if (i === 1) {

                resolve(true);
            }
        }
        setTimeout(
            ()=> {
                reject(false);
            }, 10000);
    })
}

export const insertElementsAtEnd = (elements, referenceNode) => {
    const arrayNodes = Array.from(elements);
    // return referenceNode.appendChild(arrayNodes)
    return new Promise ((resolve)=> {
        _.map(arrayNodes, item => {
            referenceNode.appendChild(item);
            window.summerExhibition.macyLayout.appended(item);
        }, resolve())
    })
};

export const prependElement = (element, referenceNode) => {
    referenceNode.insertBefore(element, referenceNode.firstChild)
}

export const appendElement = (element, referenceNode) => {
    referenceNode.appendChild(element)
}

export const wrapElement = (innerHtml) => {
    const element = document.createElement('div');
    element.innerHTML = innerHtml;
    return element
};

export const removeElement = (element) => {
    element.parentNode.removeChild(element);
};

export const showHideElement = (element, displayStyle) => {
    element.style.display = displayStyle;
}

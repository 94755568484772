const readmore = (block) => {
    const button = block.querySelector('[data-readmore="button"]')
    const text = block.querySelector('[data-readmore="text"]')
        const readmore_class = 'readmore__showText'
    if (button) button.addEventListener('click', (e)=> {
        e.preventDefault()
        block.classList.toggle(readmore_class)
    });
}

export default readmore

import {showHideElement} from "../helpers/domElements";
import {getBreakPoints} from "../helpers/breakpoints";
import zoom from "./zoom"

export default class ModalPopup {
  static init = (item) => {
    const type = item.getAttribute('data-modal');
    const modalClose = document.getElementById('modal__close');
    const modalBackground = document.getElementById('modal__background');
    modalBackground.addEventListener('click', () => ModalPopup.hideModal());
    modalClose.addEventListener('click', () => ModalPopup.hideModal());
    document.addEventListener("keyup", (event)=> {
      if (event.key === "Escape") {
        ModalPopup.hideModal()
      }
    });
    item.addEventListener('click', ()=> ModalPopup.showModal(type, item));
  };

  static showModal = (type, item)=> {
    const breakpoints = getBreakPoints();
    if (type === 'imageZoom' && window.innerWidth > breakpoints.small) {
      showHideElement(document.getElementById('modal'), 'flex');
      showHideElement(document.getElementById('modal__enquiry'), 'none');
      showHideElement(document.getElementById('modal__image'),'block');
      ModalPopup.modalSize(type, item.offsetWidth, item.offsetHeight);
      if (!document.getElementById('modal__inner').classList.contains('js__modal__inner--zoomRendered')) {
        document.getElementById('modal__inner').classList.add('js__modal__inner--zoomRendered')
        zoom();
      }
    }
    if (type === 'enquiryForm') {
      const message = item.getAttribute('data-message');
      const label = document.getElementById("modal__message");
      if (message && label) {
        label.innerHTML = message;
      }
      showHideElement(document.getElementById('modal'), 'flex');
      showHideElement(document.getElementById('modal__enquiry'), 'block');
      showHideElement(document.getElementById('modal__image'),'none');
      ModalPopup.modalSize(type);
    }
    document.getElementById('modal__close').focus();
  };

  static hideModal = () => {
    showHideElement(document.getElementById('modal'), 'none')
  };

  static modalSize = (type, imageWidth = 0, imageHeight = 0) => {
    const modalInner = document.getElementById('modal__inner');
    let imageDimensions = {};
    const offset = 80;
    const windowWidth = (window.innerWidth/100)*offset;
    const windowHeight = (window.innerHeight/100)*offset;
    const windowOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    const imageOrientation = imageWidth > imageHeight ? 'landscape' : 'portrait';
    if (type === 'imageZoom') {
      const aspectRatio = imageWidth / imageHeight;
      ((windowOrientation === 'landscape' && imageOrientation === 'landscape')
          || (windowOrientation === 'portrait' && imageOrientation === 'landscape')) && (windowWidth / aspectRatio < windowHeight) ?
          imageDimensions = {width: `${windowWidth}px`, height: `${windowWidth / aspectRatio}px`} :
          imageDimensions = {width: `${windowHeight * aspectRatio}px`, height: `${windowHeight}px`};
    }
    else {
      windowWidth > 600 ? imageDimensions.width = '600px' : '100%';
      windowHeight > 600 ? imageDimensions.height = '600px' : '100%';
    }
    modalInner.style.maxWidth = imageDimensions.width;
    modalInner.style.maxHeight = imageDimensions.height;
  }
};


const ECOMMERCE_CATEGORY = "SE Online";
const TAG_CATEGORY = 'summer';
const DIMENSION = "Summer Explorer";

export default class dataLayer {
    static initScrape = () => {
        const cardClass = 'card--artwork';
        const artworkCardClass = 'artworkCard';
        const cards = document.getElementsByClassName(cardClass);
        const artwork = document.getElementsByClassName(artworkCardClass);
        if (cards.length > 0) {
            this.registerCards(cards);
        }
        if (artwork.length > 0) {
            this.gtmAddArtwork(artwork[0]);
            // page view now registered in page template before gtm contain has loaded.
            // this.gtmArtworkPageView(artwork[0])
        }
    };

    static registerCards = (cards) => {
        let result = _.map(cards, (card) => {
            const page = card.getAttribute('data-pagination-page') ? card.getAttribute('data-pagination-page') : 0;
            const gtmObj = {
                'name':card.getAttribute('data-gtm-title'),
                'id': card.getAttribute('data-gtm-id'),
                'artworkCatalogueNumber': card.getAttribute('data-catalogue-number'),
                'type': card.getAttribute('data-gtm-type'),
                'artist': card.getAttribute('data-gtm-artist'),
                'category': ECOMMERCE_CATEGORY,
                'list': [window.location.protocol, '//', window.location.host, window.location.pathname].join(''),
                'dimension13': DIMENSION,
                'position': `${card.getAttribute('data-artwork-index')}`
            };

            _.map(card.getElementsByTagName('a'), link => {
                link.addEventListener('click', (event) => dataLayer.gtmArtworkLinkClicked(event, gtmObj, link));
            });

            return gtmObj
        });
        window.dataLayer.push({
            'event': 'productImpression',
            'ecommerce': {
                'impressions': result
            }
        });
    };

    static gtmArtworkLinkClicked = (event, gtmObj, linkNode) => {

        const href = linkNode.getAttribute('href');
        const list = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
        const name = gtmObj.name;
        const id = gtmObj.id;
        const position = gtmObj.position;
        const clickWithButton = (event.ctrlKey || event.shiftKey || event.metaKey || // apple
            (event.button && event.button == 1) // middle click, >IE9 + everyone else
        );
        let clickEvent = {
            'event': 'productClick',
            'ecommerce': {
                'click': {
                    'actionField': {'list': list },
                    'products': [{
                        'name': name,
                        'id': id,
                        'category': ECOMMERCE_CATEGORY,
                        'position': position
                    }]
                }
            },
            'eventCallback': clickWithButton  ? false : function() {
                document.location = href;
            }
        };
        window.dataLayer.push(clickEvent);
    };

    static gtmAddGenericEvent = (eCategory, eAction, eLabel ) => {
        window.dataLayer.push({
            event: TAG_CATEGORY,
            eventCategory:   eCategory,
            eventAction: eAction,
            eventLabel: eLabel
        })
    };

    static gtmAddArtwork = (artwork) => {
        let artworkGtmResult = {};
        const name = artwork.getAttribute('data-gtm-title');
        const id = artwork.getAttribute('data-gtm-id');
        const type = artwork.getAttribute('data-gtm-type');
        const artist = artwork.getAttribute('data-gtm-artist');
        const medium = artwork.getAttribute('data-gtm-medium');
        const gallery =  artwork.getAttribute('data-gtm-gallery');
        const editionSaleOnline =  artwork.getAttribute('data-gtm-sale-online');
        const exhibitedSaleOnline =  artwork.getAttribute('data-gtm-exhibited-online');
        const editionPrice =  artwork.getAttribute('data-gtm-edition-price');
        const exhibitedPrice = artwork.getAttribute('data-gtm-exhibited-price');

        window.dataLayer.push({
            'event': 'productDetail',
            'ecommerce': {
                'detail': {
                    'actionField': {},
                    'products': [{
                        'name': name,
                        'id': id,
                        'artist': artist,
                        'type': type,
                        'category': ECOMMERCE_CATEGORY,
                        'list': 'Artwork page',
                        'dimension13': DIMENSION,
                        'position': 1
                    }]
                }
            }
        });
    }

    static gtmArtworkPageView = (artwork) => {
        // This is now done  in the page template
        const name = artwork.getAttribute('data-gtm-title');
        const id = artwork.getAttribute('data-gtm-id');
        const type = artwork.getAttribute('data-gtm-type');
        const artist = artwork.getAttribute('data-gtm-artist');
        const medium = artwork.getAttribute('data-gtm-medium');
        const gallery =  artwork.getAttribute('data-gtm-gallery');
        const editionSaleOnline =  artwork.getAttribute('data-gtm-sale-online');
        const exhibitedSaleOnline =  artwork.getAttribute('data-gtm-exhibited-online');
        const editionPrice =  artwork.getAttribute('data-gtm-edition-price');
        const exhibitedPrice = artwork.getAttribute('data-gtm-exhibited-price');
        const catalogueNumber = artwork.getAttribute('data-gtm-catalogue-number');
        window.dataLayer.push({
            'event': 'summer explorer metadata',
            'id': id,
	        'name': name,
            'artistName': artist,
            'artworkTitle': name,
            'artworkType': type,
            'artworkMedium': medium,
	        'artworkFullPrice': editionPrice,
	        'artworkExhibitionPrice': exhibitedPrice,
            'artworkCatalogueNumber': catalogueNumber,
            'room': gallery,
            'pageType': 'artwork',
            'relevantExhibition': 'Summer Exhibition 2022'
        })
    }
}

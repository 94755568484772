import imagePicker from '../components/imagePicker';
import { getWindow } from '../helpers/isPatternlab';
import debounce from '../helpers/debounce'

const imageLazyLoad = () => {
    imagePicker();
    const thisWindow = getWindow();
    const debounceHander = debounce(300, () => imagePicker());
    thisWindow.addEventListener('scroll', debounceHander, {passive: true});
    thisWindow.addEventListener('resize', debounceHander, {passive:true});
};

export default imageLazyLoad

import AjaxWrapper from '../helpers/ajaxRequest';
import emailValidator from '../helpers/emailValidator';

class Newsletter {
  INVALID_MESSAGE = 'Please enter a valid email.';
  SUCCESS_MESSAGE = "Thank you for signing up to our mailing list. We'll be in touch soon.";

  constructor(options) {
    if (options == null) { options = {}; }
    this.container      = options.container;
    this.first_name     = options.first_name;
    this.last_name        = options.last_name;
    this.interests     = options.interests;
    this.original_source_loc        = options.original_source_loc;
    this.email          = options.email;
    this.dedupeToken    = options.dedupeToken;
    this.emailToken     = options.emailToken;
    this.endpoint       = options.endpoint;
    this.errorContainer = options.errorContainer;
    this.submit         = options.submit;
    this._ajax          = new AjaxWrapper();
  }

  bindEvents() {
    this.email.blur(() => {
      return this._verifyEmail();
    });
    return this.submit.click(() => {
      this._verifyEmail();
      if (this._validEmail()) {
        this._postForm().then((response) => {
          if (response.code === 200) {
            this._showSuccessMessage();
          }
          else {
            this._showFailureMessage();
          }
        })
      }
      return false;
    });
  }

  _postForm() {
    if (this._validEmail()) { return this._ajax.perform(this._ajaxOptions()); }
  }

  _verifyEmail() {
    if (!this._validEmail()) {
      return this._displayErrorMessage();
    } else {
      return this._clearErrorMessage();
    }
  }

  _validEmail() {
    return emailValidator(this._inputEmail());
  }

  _displayErrorMessage() {
    return this.errorContainer.text('Please enter a valid email.');
  }

  _clearErrorMessage() {
    return this.errorContainer.text('');
  }

  _showSuccessMessage() {
    return this.container.html(`<p data-id='success'>Thank you for signing up to our mailing list. We'll be in touch soon.</p>`);
  }

  _showFailureMessage() {
    return this.container.html(`<p data-id='error' class="error">There was a problem signing up, please try again later</p>`);
  }

  _inputFirstName() {
    return this.first_name.val();
  }

  _inputLastName() {
    return this.last_name.val();
  }

  _inputInterests() {
    return this.interests.val();
  }

  _inputOriginalSourceLoc() {
    return this.original_source_loc.val();
  }

  _inputEmail() {
    return this.email.val();
  }

  _inputDedupeToken() {
    return this.dedupeToken.val();
  }

  _inputEmailToken() {
    return this.emailToken.val();
  }

  _ajaxOptions(ids) {
    this.submit.addClass('btn--loading');
    return {
      method : 'POST',
      url    : this.endpoint,
      data   : this._data(),
      xhrFields: { withCredentials: true },
      error: (error) => {
         this._showFailureMessage();
      }
    };
  }

  _data() {
    const data = {};
    data[this.first_name.attr('name')] = this._inputFirstName();
    data[this.last_name.attr('name')] = this._inputLastName();
    data[this.email.attr('name')] = this._inputEmail();
    data[this.dedupeToken.attr('name')] = this._inputDedupeToken();
    data[this.emailToken.attr('name')] = this._inputEmailToken();
    data[this.interests.attr('name')] = this._inputInterests();
    data[this.original_source_loc.attr('name')] = this._inputOriginalSourceLoc();
    return data;
  }
}

export const renderNewsletter = () => {
    var container      = $('[data-id="newsletter-container"]');
    var first_name     = $('[data-id="newsletter-first_name"]');
    var last_name        = $('[data-id="newsletter-last_name"]');
    var interests      = $('[data-id="newsletter-interests"]');
    var original_source_loc  = $('[data-id="newsletter-original_source_loc"]');
    var email          = $('[data-id="newsletter-email"]');
    var dedupeToken    = $('[data-id="_dedupe"]');
    var emailToken     = $('[data-id="_email_field"]');
    var endpoint       = container.attr('data-url');
    var errorContainer = $('[data-id="newsletter-error-message"]');
    var submit         = $('[data-id="newsletter-submit"]');

    (new Newsletter({
      container      : container,
      first_name     : first_name,
      last_name      : last_name,
      interests       : interests,
      original_source_loc: original_source_loc,
      email          : email,
      dedupeToken    : dedupeToken,
      emailToken     : emailToken,
      endpoint       : endpoint,
      errorContainer : errorContainer,
      submit         : submit
    })).bindEvents();
}

export default Newsletter;

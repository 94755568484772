import { getWindow } from './isPatternlab';

const inViewport = (element, offset = 0) => {
    const distance = element.getBoundingClientRect();
    const thisWindow = getWindow();
    return (
        (distance.top >= (0 - offset) &&
            distance.left >= (0 - offset) &&
            distance.bottom <= (thisWindow.innerHeight + offset) &&
            distance.right <= (thisWindow.innerWidth + offset)) ||
        distance.top <= (thisWindow.innerHeight + offset)
    )
};

export default inViewport

import {appendElement, removeElement, wrapElement} from "../helpers/domElements";
import {stringify, parse} from "query-string";
import infiniteScroll from "./infiniteScroll"
import axios from 'axios';
import masonryInit from "./masonryLayout";
import dataLayer from './dataLayer';
import imagesLoaded from "imagesloaded";
import imagePicker from "./imagePicker";

export default class InitUserFavourites {
    maxFavouriteAmount;
    constructor(cards, isArtworkPage) {
        this.maxFavouriteAmount = 24;
        this.isArtworkPage = isArtworkPage
        this.alreadyAddedText = 'Favourited';
        this.favouriteLimitReachedText = 'Favourite limit reached';
        this.addFavouriteText = 'Add to favourites';
        this.favouritedHandleClass = 'js__card__favourite--selected';
        this.limitReachedClass = 'js__card__favourite--limitReached';
        if (cards[0].querySelectorAll('[data-add-favourite]').length > 0) {
            this.initCardHandle(cards);
        }

        this.setCustomTitle();
    }

    initCardHandle = (cards) => {
        _.map(cards, card => {
          if (this.notAlreadyInitialised(card)) {
              this.checkFavourites(card, getFavouritesFromLocal());
              const handle = card.querySelectorAll('[data-add-favourite]')[0];
              const catalogueNumber = card.getAttribute('data-catalogue-number');
            if (!this.isArtworkPage) handle.setAttribute('data-catalogue-number', catalogueNumber);
            handle.addEventListener('click', (event) => {
              if (!event.srcElement.classList.contains('js__card__favourite--selected') && this.checkFavouriteLimit()) {
                this.addToFavourites(handle.getAttribute('data-catalogue-number'));
                this.addGtmEvent(card, true);
                this.updateFavourite(handle, true);

              }  else {
                this.removeFromFavourites(handle.getAttribute('data-catalogue-number'));
                this.addGtmEvent(card, false);
                this.updateFavourite(handle, false);

              }
            })
          }
        });
      if (!this.checkFavouriteLimit()) this.favouriteLimitReached(true);
    };

    addGtmEvent = (card, addFav = true) => {
        dataLayer.gtmAddGenericEvent('Favourite',
            this.checkFavouriteLimit() ? addFav ? 'Add' : 'Remove' : 'Favourite limit reached',
            this.isArtworkPage ? card.getAttribute('data-catalogue-number') : event.srcElement.closest('.card').getAttribute('data-gtm-title'));
    };

    setCustomTitle = () => {
       const title = this.getTitle();
       if (title) document.getElementById('customTitle').innerText = title;
    };

    getTitle() {
        const parsedUrl = parse(window.location.search);
        return parsedUrl.title ? `${parsedUrl.title}` : false;
    }

    initArtworkCardHandle = () => {
      const handle = card.querySelectorAll('[data-add-favourite]')[0];
    };

    notAlreadyInitialised = (card) => {
        const initClass = 'js__card--userFavInit'
        if (!card.classList.contains(initClass) && card.querySelectorAll('[data-add-favourite]').length > 0) {
            card.classList.add(initClass);
            return true
        }
        else false
    }

    checkFavourites = (card, favourites) => {
      const handle = this.getHandle(card);

      const catalogueNumber = this.isArtworkPage ? handle.getAttribute('data-catalogue-number') : card.getAttribute('data-catalogue-number')
      if (_.includes(favourites, catalogueNumber)) {
        this.updateFavourite(handle, true)
      } else {
        this.updateFavourite(handle, false)
      }
    };

    checkFavouriteLimit = () => {
        return getFavouritesFromLocal().length < this.maxFavouriteAmount;
    };

    favouriteLimitReached = (limitReached) => {
        _.map(document.querySelectorAll('[data-add-favourite]'), handle => {
            if (!handle.classList.contains(this.favouritedHandleClass))  {
                limitReached ? handle.innerText = (this.favouriteLimitReachedText) : handle.innerText = (this.addFavouriteText);
            }
            limitReached ? handle.classList.add(this.limitReachedClass) : handle.classList.remove(this.limitReachedClass);
        });
    };

    getHandle = (card) => {
        return card.querySelectorAll('[data-add-favourite]')[0]
    };

    addToFavourites = (catalogueNumber) => {
        localStorage.setItem("raUserFavourites", JSON.stringify([...getFavouritesFromLocal(), catalogueNumber]));
    };

    removeFromFavourites = (catalogueNumber) => {
        const newFavourites = _.filter(getFavouritesFromLocal(), item => {
            return item !== catalogueNumber
        });
        localStorage.setItem("raUserFavourites", JSON.stringify(newFavourites));
    };

    updateFavourite = (handle, addFavourite) => {
        if (addFavourite) {
            handle.classList.add(this.favouritedHandleClass);
            handle.innerText = this.alreadyAddedText;
        }
        else {
            handle.classList.remove(this.favouritedHandleClass);
            handle.innerText = this.checkFavouriteLimit() ?  this.addFavouriteText : this.favouriteLimitReachedText;

        }
        this.checkFavouriteLimit() ? this.favouriteLimitReached(false) : this.favouriteLimitReached(true)

    };
}

const getFavouritesFromLocal = () => {
    return localStorage.getItem("raUserFavourites") ? JSON.parse(localStorage.getItem("raUserFavourites")) : [];
};

const getTitleFromLocal = () => {
    return localStorage.getItem("raUserTitle") ? localStorage.getItem("raUserTitle") : '';
};

export class GetUserFavourites {
    constructor() {
        const shareButton = document.getElementById('shareFavouritesButton');
        if (getFavouritesFromLocal().length > 0) {
            this.renderUserFavourites();

        }
        else {
            document.getElementById('renderApp').classList.add('js__appRendered');
            document.getElementById('favourites__noFavourites').classList.remove('d-n');
            document.getElementsByClassName(' favourites__header')[0].classList.add('d-n');

        }
        shareButton.addEventListener('click', (event)=> {
            event.preventDefault();
            dataLayer.gtmAddGenericEvent('Favourite', 'Copy to clipboard', this.getArtworksUrl().toString());
            this.copyToClipboard(shareButton);
        });

        const title = window.localStorage.getItem('raUserTitle');
        if (title) document.getElementById('titleFavourites').value = title;

        document.getElementById('titleFavourites').addEventListener('input', (e) => {
          window.localStorage.setItem('raUserTitle', e.target.value);
        })
    };

    renderUserFavourites() {
        return axios.get(this.getArtworksUrl()).then(res => {
            let dataBody =  wrapElement(res.data);
            const paginationItems = Array.from(dataBody.getElementsByClassName('pagination'));
            _.map(paginationItems, item=> {
                removeElement(item)
            });
            appendElement(dataBody.querySelectorAll('[data-artworks-container]')[0], document.getElementById('renderApp'));
            window.summerExhibition.userFavourites = new InitUserFavourites(document.getElementsByClassName('card--artwork'));
            const masonryGroup = document.getElementsByClassName('cardGroup--masonry');
            imagesLoaded(masonryGroup, ()=> {
                window.summerExhibition.macyLayout = new masonryInit(masonryGroup[0].getElementsByClassName('grid')[0]);

            });
            document.getElementById('renderApp').classList.add('js__appRendered');
            imagePicker(false, false, true);
            dataLayer.gtmAddGenericEvent('Favourites page', 'Total', JSON.parse(localStorage.getItem("raUserFavourites")).length.toString());
            dataLayer.gtmAddGenericEvent('Favourites page', getTitleFromLocal().toString(), JSON.parse(localStorage.getItem("raUserFavourites")).join().toString());

        });
    };

    initSharing() {
        document.getElementById('shareFavourites').setAttribute('value', this.getArtworksUrl());
    }


    getArtworksUrl() {
        const pathname = window.location.pathname.replace('/favourites', '').replace('/artworks', '');
        return `${window.location.origin}${pathname}/artworks?catalogue_numbers[]=${getFavouritesFromLocal().join()}&title=${encodeURI(getTitleFromLocal())}`;
    }


    copyToClipboard(button) {
            this.initSharing();
            const copyText = document.getElementById("shareFavourites");
            copyText.select();
            document.execCommand("copy");
            button.classList.add('js__linkCopied');
            setTimeout(()=> {
                button.classList.remove('js__linkCopied');
            },3000)
        }
}


import Packery from 'packery';
import {getBreakPoints} from "../helpers/breakpoints";
import imagesLoaded from "imagesloaded";

// const masonryInit = (grid) => {
//     grid.classList.add('js__macyRunning');
//     const breakpoints = getBreakPoints();
//     return new Macy({
//         container: grid,
//         trueOrder: false,
//         waitForImages: false,
//         margin: 0,
//         columns: 4,
//         breakAt: {
//             [breakpoints.xlarge]: 4,
//             [breakpoints.large]: 2,
//             [breakpoints.small]: 1
//         }
//     });
// };

const masonryInit = (grid) => {
    // grid.classList.add('js__macyRunning');
        _.map(grid.getElementsByClassName('card--artwork'), item => {
            imagesLoaded(item, ()=> {
                item.classList.add('card--showCard');
            });
        });

    return new Packery(grid, {
        itemSelector: '.card--artwork',
        gutter: 0,
        stamp: '.stamp',
        percentPosition: true,
        isLayoutInstant: true
    });
};

export default masonryInit

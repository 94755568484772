import debounce from '../helpers/debounce'

export const hideOnScroll = (element) => {
    let prevScrollpos = window.pageYOffset;
    const debounceHander = debounce(50, () => debounceHanderFn());
    const navHeight = 50;
    window.addEventListener('scroll', debounceHander);
    const debounceHanderFn = ()=> {
        if (window.pageYOffset > 49) {
            document.body.classList.add('js__scrolling');
            document.body.classList.add('js__navigation--hidden');
        }
        else {
            document.body.classList.remove('js__scrolling', 'js__navigation--hidden')
        }
        if (document.getElementsByClassName('body--summerHomepage').length > 0) {
            if (window.pageYOffset > 750) {
                document.body.classList.add('js__navReset');
            }
            else {
                document.body.classList.remove('js__navReset')
            }
        }
    }
};

export const mobileNavigation = (element) => {
    element.getElementsByClassName('navigation__mobileHandle')[0].addEventListener('click', (event)=> {
        event.preventDefault()
        element.classList.contains('js__showNavigation') ? element.classList.remove('js__showNavigation') : element.classList.add('js__showNavigation');
    })
};

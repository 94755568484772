import emailValidator from '../helpers/emailValidator';
export default class EnquiryForm {
    formEl;
    emailField
    nameField
    telephoneField
    messageField
    submitButton
    constructor(buttonElement) {
        buttonElement.addEventListener('click', ()=> {
            this.formEl = document.querySelector("[data-enquiry-form]");
            this.emailField = this.formEl.querySelector("#email");
            this.nameField = this.formEl.querySelector("#full_name");
            this.telephoneField = this.formEl.querySelector("#telephone_number");
            this.messageField = this.formEl.querySelector("#message");
            this.submitButton = this.formEl.querySelector(".js__submitEnquiryForm");
            this.init()
        })

    }
    init() {
        this.submitButton.addEventListener('click', (event)=> {
            this.validateForm() ? this.submitButton.classList.add('btn--loading') : event.preventDefault();
        })
    }

    validateForm() {
        const emailValid = this.isValid(this.emailField, "email")
        const nameValid = this.isValid(this.nameField, "text")
        const messageValid = this.isValid(this.messageField, "text")
        const telephoneValid = this.isValid(this.telephoneField, "text")

        return emailValid && nameValid && messageValid && telephoneValid
    }

    isValid(field, type) {
        let valid = true;
        switch(type) {
            case "email":
                valid = emailValidator(field.value)
                break;
            case "text":
                valid = field.value.length > 2
                break;
            default:
                break;
        }
        this.addRemoveError(valid, field)
        return valid
    }

    addRemoveError(isValid, el) {

        isValid ?  el.classList.remove('field--error') : el.classList.add('field--error')
    }
}
const accessibilty = () => {
    const keyActive = 'js__keyTabbing__active';
    document.addEventListener('keyup', (event) => {
        if ((event.key !== 'Alt') && (event.key !==  'Shift')) {
            _.map(document.getElementsByClassName(keyActive), item => {
                item.classList.remove(keyActive)
            });
            if (event.keyCode === 9) {
                event.srcElement.classList.add(keyActive);
            }
            if (event.key === 'Enter') {
                event.srcElement.classList.add(keyActive);
            }
        }
    });
};

export const keepFocusInPage = (section) => {
        const focusHandler = (event) => {
            const tags = ['a', 'button', 'input', 'textarea', 'select'];
            let focusableElements = [];
            _.map(tags, item=> {
                focusableElements = [...focusableElements, ...section.getElementsByTagName(item)]
            });
            if (event.keyCode === 9 && document.activeElement === focusableElements[focusableElements.length - 1]) {
                focusableElements[0].focus();
            }
        };
    if (!section.classList.contains('js__section--focused')) {
        section.classList.add('js__section--focused');
        section.addEventListener('keydown', focusHandler, false);
    }
};

export const removeKeepFocusInPage = (section) => {
    section.classList.remove('js__section--focused');
    section.removeEventListener('keydown', focusHandler, false);
};

export const buttonLoading = (btn, loading) => {
    btn.addEventListener('click', ()=> {
        loading ? btn.classList.add('btn--loading') :  btn.classList.remove('btn--loading');
    })
};



export default accessibilty;

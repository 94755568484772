export const textDropdown = (dropdown_items) => {
    const array = Array.prototype.slice.call(dropdown_items)
    array.map(d => {
        d.querySelectorAll('.textDropdown__handle a')[0].addEventListener('click', (event)=> {
            event.preventDefault()
            d.classList.contains('open') ? d.classList.remove('open') : d.classList.add('open')
        })
    })
};

export const focusDropdown = (el) => {
    const input = el.querySelectorAll('input')[0]
    input.addEventListener('focus', ()=> {
        el.classList.add('focusDropdown--show');
    })
    document.body.addEventListener('click', (event)=> {
        el.classList.remove('focusDropdown--show');
    })
   document.querySelector('.focusDropdown').addEventListener('click', (event)=> {
       event.stopPropagation();
   })
}



export const getCurrentBreakpointName = () => {
    let breakpointName;
    breakpointName =
        window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
    return breakpointName;
};

export const getBreakPoints = () => {
    const  breakpointValues = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/px|"/g, '');
    var breakPointArray = breakpointValues.split(',');
    let breakpoints = {};
     _.map(breakPointArray, item=> {
        const key = item.substring(0, item.indexOf("="));
        const value = (item.split('=').pop()) -1;
         breakpoints = {...breakpoints, [key] : value}
    });
    return breakpoints;
};

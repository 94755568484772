import debounce from '../helpers/debounce'

export default class viewOnWall {
    roomImage
    mainImage
    roomNo;
    debug;
    constructor(el) {
        this.roomNo = el.getAttribute('data-view-on-wall-room-id')-1 || 0;
        this.mainImage = {
            image: el.getAttribute('data-view-on-wall-image'),
            width: parseInt(el.getAttribute('data-view-on-wall-image-width')),
            height: parseInt(el.getAttribute('data-view-on-wall-image-height'))
        }

        $(el).click((event)=> {
            event.preventDefault();
            this.init(wallImages[this.roomNo]);
        })
        this.debug = false
    }
    template = (mainImage, roomImage) => {
        return `<div class="viewOnWall">
                    <div class="viewOnWall__inner">
                            ${this.debug ? `<div class="viewOnWall__debug">Room number=${this.roomNo+1} / Wall width= ${roomImage.width} / Wall height = ${roomImage.height} </div>`: ''}
                            <div class="viewOnWall__navigation">
                                <button class="btn  btn--hiddenText btn--noBorder btn--close btn--large viewOnWall__navigation__close">Close</button>
                      
                                ${this.debug ? `<a href="#" class="btn btn--primary  btn--hiddenText btn--noBorder btn--arrowForward btn--large viewOnWall__navigation__next">New room</a>`: ''}
                            </div>
                            <div class="viewOnWall__room">
                                <img class="viewOnWall__room__image" src="${roomImage.path}">
                                <div class="viewOnWall__artwork" style="${roomImage.artworkArea}">
                                     <img src="${mainImage.image}" class="viewOnWall__artwork__image">
                                </div>
                            </div>
                    </div>
              </div>`
    }

    init = (roomImage) => {

        $('body').prepend(this.template(this.mainImage, roomImage))

        $('.viewOnWall__navigation__close').click(()=> {
            $('.viewOnWall').remove();
        });

        // Not used change room
        $('.viewOnWall__navigation__next').click(()=> {
            this.nextRoomNo()
            $('.viewOnWall').remove();
            this.init(wallImages[this.roomNo]);
        });

        const roomImageEl = document.querySelectorAll('.viewOnWall__room__image')[0];
        const $roomImageEl = $('.viewOnWall__room__image');
        const $roomEl = $('.viewOnWall__room');
        const artworkImageEl = document.querySelectorAll('.viewOnWall__artwork__image')[0];
       // const $artworkImageEl = $('.viewOnWall__artwork__image');

        this.waitForImageToLoad(roomImageEl).then(()=> {
            // room image has loaded
            $roomEl.css('opacity', 1)
            $(artworkImageEl).css('opacity', 0);

            const percentWidth = (this.mainImage.width/roomImage.width)*100;
            const percentHeight = (this.mainImage.height/roomImage.height)*100;
            if (this.debug) console.log(percentHeight)
            this.mainImage.width >= this.mainImage.height ? $(artworkImageEl).css('width', `${percentWidth}%`) : $(artworkImageEl).css('height', `${percentHeight}%`)


            // RESIZE BROWSER
            this.resize($roomImageEl, artworkImageEl)
            const debounceResize = debounce(250, ()=> {
                $roomEl.css('left', 0)
                this.resize($roomImageEl, artworkImageEl)
            });
            $(window).on('resize', debounceResize);

            $(artworkImageEl).css('opacity', 1);
        }).catch(error => {
            console.log(error)
        });
        this.waitForImageToLoad(artworkImageEl).then(()=> {
            if (this.debug) console.log('img loaded')
        });
    }

    waitForImageToLoad = (imageElement) => {
        return new Promise(resolve=>{imageElement.onload = resolve})
    }
    nextRoomNo = () => {
        const rooms = wallImages.length
        this.roomNo >= rooms-1  ? this.roomNo = 0 : this.roomNo ++
        if (this.debug) console.log(wallImages[this.roomNo], wallImages[this.roomNo].width, this.mainImage.width, wallImages[this.roomNo].height, this.mainImage.height)
        if (parseInt(wallImages[this.roomNo].width) < this.mainImage.width || parseInt(wallImages[this.roomNo].height) < this.mainImage.height) {
            if (this.debug) console.log('image too big')
            this.nextRoomNo()
        }

    }
    resize = (roomImageEl, artworkImageEl) => {
        const windowHeight = window.innerHeight
        const windowWidth = window.innerWidth

        this.checkRoomImageSize(roomImageEl, windowWidth, windowHeight)
        this.checkArtworkImageSize(artworkImageEl, windowWidth, windowHeight, roomImageEl)


    }
    checkRatio = (width, height) => {
        return width/height
    }
    checkArtworkImageSize = (artworkImageEl, winW, winH, roomImageEl) => {
        $(artworkImageEl).css('opacity',0)
        const artworkImageHeight = $(artworkImageEl).outerHeight();
        const artworkImageWidth = $(artworkImageEl).outerWidth()
        const boundingClient = artworkImageEl.getBoundingClientRect();
        const roomElParent = roomImageEl.parent()
        const imageCalc = (boundingClient.x + artworkImageWidth)/winW*100
        const imageRedZone = imageCalc < 13 || imageCalc > 87

        roomElParent.css('left', 0)
        const leftMargin = (winW < artworkImageWidth + boundingClient.x) ?
            boundingClient.x - ((winW - artworkImageWidth)/2) :
            (imageRedZone && winH > winW)  ? (winW/2)-artworkImageWidth : 0;
        if (this.debug) console.log(artworkImageWidth, winW, boundingClient)
        if (this.debug) console.log(imageCalc, imageRedZone, winH > winW, leftMargin, artworkImageWidth, winW )
        roomElParent.css('left', `-${leftMargin}px`)
        $(artworkImageEl).css('opacity',1)
    }
    checkRoomImageSize = (roomImageEl, winW, winH) => {
        const roomImageHeight = roomImageEl.height();
        const roomImageWidth = roomImageEl.width()
        const roomImageRatio = this.checkRatio(roomImageWidth, roomImageHeight)
        const browserRatio = this.checkRatio(winW, winH);
        const roomElParent = roomImageEl.parent()
        if (this.debug) console.log('browser ratio: ', browserRatio, "room ratio: ", roomImageRatio )
        browserRatio < roomImageRatio ? roomElParent.addClass('js__resizeVertical') : roomElParent.removeClass('js__resizeVertical')
    }
}
import {map} from 'lodash';
import "@babel/polyfill";
import axios from 'axios';
import {
  appendElement,
  insertElementsAtEnd,
  insertElementsAtStart,
  wrapElement
} from '../helpers/domElements'
import inViewport from '../helpers/inViewport';
import debounce from "../helpers/debounce";
import {quickViewPreOpen} from './lightbox';
import {parse, stringify} from 'query-string';
import dataLayer from './dataLayer';
import imagePicker from "./imagePicker";
import imagesLoaded from 'imagesloaded';
import masonryInit from "./masonryLayout";



export default class infiniteScroll {
  page;
  count = 0;
  infiniteAmountNext = 5;
  infiniteAmountPrevious = 2;
  page_number;
  pageGrid;
  cardPageAttr = 'data-pagination-page';
  loadPreviousClass = 'infiniteScroll__load--previous';
  loadNextClass = 'infiniteScroll__load--next';
  pageString = '?page=';
  loadingInProgressClass = 'js__infiniteScroll--loadingInProgress';
  lightboxOpen;
  scrollPageAnchorPoints = [];
  preloadingInProgress;

  constructor() {
    this.page = document.querySelectorAll("[data-pagination='infiniteScroll']")[0];
    this.pageGrid = this.page.querySelectorAll(".grid")[0];
    this.lightboxOpen = false;
    this.page_number = this.getPageNumberFromUrl(window.location.search) || 0;
    this.preloadingInProgress = false
    this.init( true, this.page_number, false);
  }

  init = (preload = true, pageNumber, reinit) => {
    this.paginationShowingInit();
    this.loading(false);
    if ((pageNumber > 0) && preload) {
      this.preloadingInProgress = true;
      document.body.scrollTop = 0;
      history.scrollRestoration = 'manual';
      this.loading(true);
      this.preLoadPages(pageNumber);
    }
    if ((!preload || pageNumber !== 0) && !quickViewPreOpen()) this.updateUrl(pageNumber);
    if (!reinit) this.pagerInit(reinit, this.page);
  };

  getNextPage = (nextPageUrl, previous, preloadFinished = false, callback) => {
    const pageNumber = this.getPageNumberFromUrl(nextPageUrl);
    this.loading(true);
    axios.get(nextPageUrl).then((res)=> {
      let dataBody =  wrapElement(res.data);
      let infiniteScrollPage = wrapElement(dataBody.querySelectorAll(".infiniteScroll__page")[0].innerHTML);
      let cards = this.getCards(infiniteScrollPage);
      if (cards.length === 0) {
        this.removePagination(this.page, previous);
        this.loading(false);
        return;
      }
      infiniteScrollPage.getElementsByClassName('card--artwork')[0].setAttribute('data-new-page-number',pageNumber);
      dataLayer.registerCards(cards);
      if (window.summerExhibition.userFavourites) window.summerExhibition.userFavourites.initCardHandle(cards);
      if (window.summerExhibition.lightbox) window.summerExhibition.lightbox.init(infiniteScrollPage.querySelectorAll('[data-lightbox-item]'));



      if (previous) {
        insertElementsAtStart(cards, this.pageGrid).then(()=> {
          imagesLoaded( this.pageGrid, ()=> {
            window.summerExhibition.macyLayout.destroy();
            window.summerExhibition.macyLayout = new masonryInit(this.pageGrid)
            _.map(this.pageGrid.querySelectorAll('.card--artwork:not(.card--showCard)'), item=> {
              item.classList.add('card--showCard');
            });
            if (preloadFinished) {
              this.preloadingInProgress = false;
              this.pagerInit(true, infiniteScrollPage, previous);
              this.loading(false);
              this.scrollPage(this.getPageNumberFromUrl(window.location.search));
              this.scrollPageUrlUpdate(pageNumber);
              if (callback) callback(true, previous);
            }
            else {
                this.pagerInit(true, infiniteScrollPage, previous);
                if (!this.preloadingInProgress) this.updateUrl(pageNumber);
                this.loading(false);
                this.scrollPageUrlUpdate(pageNumber);
                if (callback) callback(true, previous);
            }
          });
          if (this.getCards(this.page)[0].getAttribute(this.cardPageAttr) === '0' ) {
            this.removePagination(this.page, previous);
          }
        });
      }
      else {
        insertElementsAtEnd(cards, this.pageGrid).then(() => {
          imagesLoaded( this.pageGrid, ()=> {
            window.summerExhibition.macyLayout.layout();
            _.map(this.pageGrid.querySelectorAll('.card--artwork:not(.card--showCard)'), item=> {
              setTimeout(()=>{item.classList.add('card--showCard')},100);
            });
            this.pagerInit(true, infiniteScrollPage, false);
            this.init(false, pageNumber, true);
            this.loading(false);
            this.scrollPageUrlUpdate(pageNumber);
            if (callback) callback(true, previous);
          });
        });
      }
      imagePicker(false, false, true);

    }).catch((error)=> {
      // handle error
      // console.log(error);
    });

  };

  pagerInit = (reInit = false, elem, previous = false) => {
    this.paginationShowingInit();
    const elemClass = (previous ? this.loadPreviousClass : this.loadNextClass);
    const pagerButtonArray = elem.getElementsByClassName(elemClass);
    const scrollPageHeader = this.page.getElementsByClassName('infiniteScroll__header')[0];
    const scrollPageFooter = this.page.getElementsByClassName('infiniteScroll__footer')[0];
    const scrollPage = document.getElementsByClassName('infiniteScroll')[0];
    const elemExists = pagerButtonArray.length > 0 ? true : false;
    let pagerButton = elemExists ? pagerButtonArray[0] : false;
    const nextPageUrl = elemExists ? pagerButton.getAttribute('href') : false;
    if (reInit && elemExists) {
      this.removePagination(this.page, previous);
      previous ? appendElement(pagerButton, scrollPageHeader) : appendElement(pagerButton, scrollPageFooter);
    }
    if (!elemExists && this.page.getElementsByClassName(elemClass).length > 0) this.removePagination(this.page, previous);
    if (elemExists) {
      if (!this.infiniteScrollActive()) {
        pagerButton.addEventListener('click', (e)=> {
          e.preventDefault();
          this.getNextPage(nextPageUrl, previous);
        });
      }
      // Auto add in next page
      const debounceHandler = debounce(100, () => {
        const footerHeight = document.getElementById('ra-shared-global-footer').length > 0 ? document.getElementById('ra-shared-global-footer')[0].clientHeight : 760;
        if (pagerButton && (window.pageYOffset + window.innerHeight > (document.body.offsetHeight - footerHeight - 2000)) && !this.lightboxOpen) {
          pagerButton = false;
          this.count++;
          this.getNextPage(nextPageUrl, false);
          return false
        }
      });

      if (!previous && this.infiniteScrollActive()) {
        window.addEventListener('scroll', debounceHandler, {passive: true});
        document.getElementsByClassName('infiniteScroll')[0].classList.add('js__infiniteScroll--autoActive');
      }
      else {
        scrollPage.style.height = `${scrollPage.clientHeight}px`;
        document.getElementsByClassName('infiniteScroll')[0].classList.remove('js__infiniteScroll--autoActive');
        window.removeEventListener('scroll', debounceHandler);
      }
    }
  };

  removePagination = (elem, previous) => {
    const elemClass = (previous ? this.loadPreviousClass : this.loadNextClass);
    _.map(elem.getElementsByClassName(elemClass), item => {
      if (previous && document.getElementsByClassName('pagination--header').length > 0) document.getElementsByClassName('pagination--header')[0].style.display = 'none';
      if(item) item.parentNode.removeChild(item);
    });
  };

  infiniteScrollActive = (direction) => {
    return direction === 'previous' ? this.count < this.infiniteAmountPrevious : this.count < this.infiniteAmountNext;
  };

  getPageNumberFromUrl = (urlParams) => {
    return urlParams.indexOf('?') !== -1 ? parse(urlParams.split('?').pop()).page : parse(urlParams).page
  };

  updateUrl = (page) => {
    let obj = parse(window.location.search);
    obj = {...obj, page: page};
    const stringifyObject = stringify(obj);
    window.history.pushState(`?${stringifyObject}`, '', `?${stringifyObject}`)
  };


  getCards = (element) => {
    return element.getElementsByClassName('card');
  };

  preLoadPages = (pageNum) => {
   // this.preloadingInProgress(true);
    for (let i = parseInt(pageNum)-1; i>= 0; i--) {
      if (this.infiniteScrollActive('previous')) {
        this.count === (this.infiniteAmountPrevious -1) ?
            this.getNextPage(this.getPageUrlRender(i), true, true) :
            this.getNextPage(this.getPageUrlRender(i), true);
      }
      this.count++;
    }
  };

  macyRecalculate = (macyInstance) => {
    return new Promise(resolve => {
      //macyInstance.layout();
      resolve(true)
    });
    //return macyInstance.reInit();
    // return macyInstance.runOnImageLoad( ()=> {
    //   macyInstance.recalculate(true, true);
    // });
  };

  loading = (loading) => {
    const el = document.getElementsByClassName('infiniteScroll__loading');
    _.map(el, (item)=> {
      loading ? item.classList.add('d-b') : item.classList.remove('d-b')
    });
    loading ? this.page.style.height = `${this.page.clientHeight}px` : this.page.style.height = 'auto';
    loading ? this.page.classList.add(this.loadingInProgressClass) : this.page.classList.remove(this.loadingInProgressClass)
  };

  scrollPage = (pageNum) => {
    const myVal = this.page.querySelectorAll('[' + this.cardPageAttr + '="' + pageNum + '"]')[0];
    myVal.scrollIntoView()
  };

  pauseCount = (bool) => {
    if (bool) {
      this.pausedCountValue = this.count;
      this.count = this.infiniteAmountNext;
    }
    else {
      this.count = this.pausedCountValue;
    }
  };


  getPageUrlRender = (pageNumber) => {
    let obj = parse(window.location.search);
    if (pageNumber !== null) {
      obj = {...obj, page: pageNumber};
    }
    const objStr = stringify(obj);
    return `${location.protocol}//${location.host}${location.pathname}?${objStr}`
  };

  paginationShowingInit = () => {
    const totalCards = this.getCards(this.page).length;
    const perPageNum = this.page.getAttribute('data-pagination-perpage');
    const firstPageNum = parseInt(this.getCards(this.page)[0].getAttribute(this.cardPageAttr)) + 1;
    const firstOfPage = (parseInt(perPageNum) * firstPageNum) - (parseInt(perPageNum)-1);
    _.map(this.page.getElementsByClassName('pagination__showing__start'), item => {
      item.innerText = firstOfPage;
    });
    _.map(this.page.getElementsByClassName('pagination__showing__end'), item => {
      item.innerText = (totalCards + firstOfPage - 1);
    });
  };

  scrollPageUrlUpdate = (pageNumber) => {
    // this.scrollPageAnchorPoints = _.map(document.querySelectorAll(`[data-new-page-number]`), page => {
    //   return {top: page.getBoundingClientRect().y, pageNumber: page.getAttribute()}
    // });
    // this.scrollPageAnchorPoints.push({top: document.querySelectorAll(`[data-new-page-number="${pageNumber}"]`)[0].getBoundingClientRect().y, pageNumber: pageNumber});
    // console.log()
  };

  proxyLoadNextPage = (pageNum, previous, callback) => {
    const loadPreviousClass = 'infiniteScroll__load--previous';
    const loadNextClass = 'infiniteScroll__load--next';
    const pageString = '?page=';
    const nextExists = previous ? document.getElementsByClassName(loadPreviousClass).length > 0 : document.getElementsByClassName(loadNextClass).length > 0;
    const nextPageNum = previous ? parseInt(pageNum) - 1 : parseInt(pageNum) + 1;
    nextExists ? this.getNextPage(this.getPageUrlRender(nextPageNum), previous , true,  callback) : callback(false);
    if (nextExists) $(`.${loadNextClass}`).on('click', (e)=> {
      e.preventDefault();
    })
  };
  proxyLightboxOpen = (boolean) => {
    this.lightboxOpen = boolean;
  }
}

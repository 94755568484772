const searchDropdown = () => {
  const button = document.getElementById('searchDropdown--button')
  const dropdown = document.getElementById('searchDropdown')
  if (button) button.addEventListener('click', (e)=> {
    e.preventDefault();
    const dropdownOpen = dropdown.classList.contains('open');
    if (dropdown.classList.contains('open')) {
      dropdown.classList.remove('open');
      document.body.classList.remove('js__navSearchDropdown--open')
      document.body.classList.remove('js__navSearchDropdown--open')
      button.querySelectorAll('span').innerHTML = 'Search artworks';
      button.classList.remove('js__search--open');
    } else {
      dropdown.classList.add('open');
      document.body.classList.add('js__navSearchDropdown--open')
      document.querySelector('.searchDropdown #search_query').focus();
      button.querySelectorAll('span').innerHTML = 'Close search';
      button.classList.add('js__search--open');
    }
  });
  const filterExpand = document.getElementById('searchDropdown--filtersButton');
  if (filterExpand) filterExpand.addEventListener('click', (event)=> {
    const filters = document.getElementById('searchDropdown--filters');
    filterExpand.classList.contains('js__open') ? filterExpand.classList.remove('js__open') : filterExpand.classList.add('js__open');
    filters.classList.contains('filters__open') ? filters.classList.remove('filters__open') : filters.classList.add('filters__open')
  })
}

export default searchDropdown

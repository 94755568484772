import $ from 'jquery';
import 'slick-carousel';
import {getBreakPoints} from "../helpers/breakpoints";
import imagePicker from "./imagePicker";

export default class Carousel {
    constructor(el) {
        this.carousel = $(el).find('.cardGroup__inner')
        this.init();
    }
    init() {
        const breakpoints = getBreakPoints();
        this.carousel.slick({
            dots: false,
            infinite: false,
            speed: 300,
            centerMode: false,
            variableWidth: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: breakpoints.large,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true
                    }
                },
                {
                    breakpoint: breakpoints.medium,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        });
        this.carousel.on('afterChange', ()=> {
            imagePicker();
        })
    }
}

import queryString from 'query-string'
import {
  appendElement
} from '../helpers/domElements'
import imagePicker from "./imagePicker";
// import {proxyLoadNextPage, proxyLightboxOpen} from './infiniteScroll';
import {keepFocusInPage, removeKeepFocusInPage} from './accessibility';
import {parse, stringify} from 'query-string';
import dataLayer from './dataLayer';


export default class lightbox {
  loading = false;
  constructor(lightboxArray) {
    this.init(lightboxArray)
  }

  init = (lightboxArray) => {
    const lightboxRendered = document.getElementById('lightbox');
    if (!lightboxRendered) {
      const lightboxWrap =  document.createElement('div');
      lightboxWrap.innerHTML = this.lightboxTemplate();
      appendElement(lightboxWrap, document.body);
      document.getElementById("lightbox__foreground").addEventListener("click", (event) => {
        this.closeLightbox();
      })
      document.getElementById("lightbox__foreground__inner").addEventListener("click", (e) => {
        e.stopPropagation()
      })
      document.addEventListener("keyup", (event) => {
        if (event.key === "Escape" && document.getElementsByClassName('js__lightboxItem--active').length > 0) {
          this.closeLightbox();
        }
      });
    }
    _.map(lightboxArray, item => {
      this.addLightboxHandle(item);
    });
    this.lightboxNavigation();
    if (quickViewPreOpen()) {
      this.openLightbox(document.querySelectorAll(`[data-catalogue-number="${quickViewPreOpen()}"]`)[0]);
    }
  };

  lightboxTemplate = () => `<div class="lightbox" id="lightbox" aria-live="assertive">
  <div class="lightbox__inner">
    <div class="lightbox__background"></div>
    <div class="lightbox__loading">
      <div class="loading">
        <div class="loading__spinner"></div>
        <p class="loading__text">Fetching more artworks</p>
      </div>
    </div>
    <div class="lightbox__foreground" id="lightbox__foreground">
      <div class="lightbox__foreground__inner" id="lightbox__foreground__inner">
      </div>
    </div>
    <div id="lightbox__controls" class="lightbox__controls">
      <div class="lightbox__buttons-wrapper">
        <a id="lightbox__escape" href="#" class="btn btn--large btn--hiddenText btn--noBorder lightbox__escape-button btn--close" aria-label="Close"  data-lightbox-nav="close">Close artwork viewer</a>
        <a id="lightbox__previous" class="btn btn--large btn--hiddenText btn--noBorder btn--circleBack" aria-label="Previous artwork" data-lightbox-nav="previous" href="#">Previous artwork</a>
        <a id="lightbox__next" class="btn btn--large btn--hiddenText btn--noBorder btn--circleForward" aria-label="Next artwork" data-lightbox-nav="next" href="#">Next artwork</a>
      </div>
    </div>
  </div>
</div>`;

  lightboxContentTemplate = (content) => `<div class="lightbox__content">
  <div class="lightbox__image">${content.image}</div>
  <div class="lightbox__details">${content.details}</div>
</div>`;

  addLightboxHandle = (item) => {
    const cardImage = item.getElementsByClassName('card__image');
    if (!item.classList.contains('js__lightboxItem')) {
      if (cardImage.length > 0) appendElement(this.createHandle(item), cardImage[0]);
      item.classList.add('js__lightboxItem');
    }
  };

  showHiddenButton = () => {
    const lightboxDetails = document.getElementsByClassName('lightbox__details');
    if (lightboxDetails[0]) {
      const hiddenButton = lightboxDetails[0].getElementsByClassName('hidden')[0];
      hiddenButton.style.display = 'block';
    }
  };

  createHandle = (item) => {
    const element = document.createElement('button');
    element.innerHTML = 'View in lightbox';
    element.className = 'lightbox__handle btn--hiddenText';
    element.setAttribute('aria-label', 'Open artwork in lightbox');
    element.setAttribute('data-gtm-click', 'Lightbox');
    element.setAttribute('aria-expanded', 'false');
    element.setAttribute('aria-controls', 'lightbox');
    element.setAttribute('data-ga4-type', 'ui');
    element.setAttribute('data-ga4-area', 'listings_lightbox');
    element.addEventListener('click', (e)=> {
      e.preventDefault();
      dataLayer.gtmAddGenericEvent('Lightbox', 'Open', element.closest('.card').getAttribute('data-gtm-title'));
      this.openLightbox(item);
      element.setAttribute('aria-expanded', 'true');
    });
    return element
  };

  openLightbox = (item, lightboxRendered=false) => {
    if (window.summerExhibition.infiniteScroll) window.summerExhibition.infiniteScroll.proxyLightboxOpen(true);
    const lightbox = document.getElementById('lightbox');
    const cardImage = item.getElementsByClassName('card__image');
    const content = {
      image : (cardImage.length > 0 ? item.querySelectorAll('.card__image .image')[0].outerHTML : ''),
      details : item.querySelectorAll('.card__content')[0].outerHTML
    };
    this.setLoading(false);
    item.classList.add('js__lightboxItem--active');
    lightbox.classList.add('lightbox--rendered');
    lightbox.getElementsByClassName('lightbox__foreground__inner')[0].innerHTML = this.lightboxContentTemplate(content);
    if (window.innerWidth > window.innerHeight) {
      // IE 10/11 doesn't let you comma separate the add classes
      lightbox.getElementsByClassName('image')[0].classList.add('image--canvas');
      lightbox.getElementsByClassName('image')[0].classList.add('image--canvas--75')
      lightbox.getElementsByClassName('lightbox__image')[0].style.maxWidth = '800px'
    }
    else {
      lightbox.getElementsByClassName('image')[0].classList.add('image--canvas');
    }


    const hasHiddenButton = lightbox.getElementsByClassName('hidden').length > 0;
    if (hasHiddenButton) this.showHiddenButton();
    window.history.pushState('', '', this.updateUrl(this.getItemMetaData()));
    if (cardImage.length > 0) imagePicker(lightbox.getElementsByClassName('image'), 599);
    item.scrollIntoView();
    if (!lightboxRendered) {
      lightbox.getElementsByTagName('a')[0].focus();
      keepFocusInPage(lightbox);
    }
    let infiniteScrollEnd = false;
    if (document.getElementsByClassName('pagination__showing__end').length > 0) {
      infiniteScrollEnd = window.summerExhibition.infiniteScroll ? document.getElementsByClassName('pagination__showing__end')[0].textContent === document.getElementsByClassName('pagination__showing__total')[0].textContent : false;
    }
    if (!this.getCurrentItem().next &&
        infiniteScrollEnd || !window.summerExhibition.infiniteScroll && !this.getCurrentItem().next)  {
      this.hideShowNavigation(false, false);
    }
    else {
      this.hideShowNavigation(false, true);
    }
    if (!this.getCurrentItem().previous && (document.getElementsByClassName('infiniteScroll__load--previous').length === 0))  {
      this.hideShowNavigation(true, false);
    }
    else {
      this.hideShowNavigation(true, true);
    }
  };

  lightboxNavigation = () => {

    const lightboxControls = document.getElementById('lightbox__controls');
    if (!lightboxControls.classList.contains('js__lightbox__controls--rendered')) {
      const lightboxControlsItems = lightboxControls.getElementsByTagName('a');
      _.map(lightboxControlsItems, button => {
        const navtype = button.getAttribute('data-lightbox-nav');
        if (navtype === 'close') {
          button.addEventListener('click', (e)=> {
            e.preventDefault();
            this.closeLightbox();
            //window.summerExhibition.infiniteScroll.proxyLightboxOpen(false);
          });
        }
        if (navtype === 'next') {
          button.addEventListener('click', (e)=> {
            e.preventDefault();
            this.setLoading(false);
            const activeItem = this.getCurrentItem();
            dataLayer.gtmAddGenericEvent('Lightbox', 'Next', document.getElementsByClassName('js__lightboxItem--active')[0].getAttribute('data-gtm-title'));
            if (activeItem.next) {
              this.removeLightboxContent(activeItem.current);
              this.openLightbox(activeItem.next, true);
            } else this.ajaxLoadCards(false);
          });
        }
        if (navtype === 'previous') {
          button.addEventListener('click', (e)=> {
            e.preventDefault();
            const activeItem = this.getCurrentItem();
            dataLayer.gtmAddGenericEvent('Lightbox', 'Previous', document.getElementsByClassName('js__lightboxItem--active')[0].getAttribute('data-gtm-title'));
            if (activeItem.previous)  {
              this.removeLightboxContent(activeItem.current);
              this.openLightbox(activeItem.previous, true);
            } else  this.ajaxLoadCards(true);
          });
        }
      });
      lightboxControls.classList.add('js__lightbox__controls--rendered');
    }
  };
  closeLightbox = () => {
    const currentItem = document.getElementsByClassName('js__lightboxItem--active')[0];
    currentItem.getElementsByTagName('a')[0].focus();
    dataLayer.gtmAddGenericEvent('Lightbox', 'Close', document.getElementsByClassName('js__lightboxItem--active')[0].getAttribute('data-gtm-title'));
    window.history.pushState('', '', this.updateUrl(this.getItemMetaData(), true),);
    currentItem.getElementsByClassName('lightbox__handle')[0].setAttribute('aria-expanded', 'false');
    this.removeLightboxContent(currentItem);
  };

  hideShowNavigation = (previous, showButton) => {
    const arrow = previous ?
        document.getElementById('lightbox__previous') :
        document.getElementById('lightbox__next');
    !showButton ?
        arrow.classList.add('d-n') :
        arrow.classList.remove('d-n');
  };

  removeLightboxContent = (current) => {
    const lightbox = document.getElementById('lightbox')
    current.classList.remove('js__lightboxItem--active');
    lightbox.classList.remove('lightbox--rendered');
    const lightboxContent = document.getElementsByClassName('lightbox__content')[0];
    lightboxContent.innerHTML = '';
  };

  getCurrentItem = () => {
    const current = document.getElementsByClassName('js__lightboxItem--active')[0];
    return {current: current, previous: this.getNextPreviousItem(current, true),  next: this.getNextPreviousItem(current, false)}
  };

  getNextPreviousItem = (current, previous) => {
    let sibling = previous ? current.previousElementSibling : current.nextElementSibling;
    if (!sibling) return false;
    return sibling.classList.contains('js__lightboxItem') ?  sibling : null;
  };

  ajaxLoadCards = (previous) => {
    const activeItem = this.getCurrentItem();
    document.getElementById('lightbox').classList.add('js__lightbox--loading');
    if (!this.loading && window.summerExhibition.infiniteScroll) window.summerExhibition.infiniteScroll.proxyLoadNextPage(activeItem.current.getAttribute('data-pagination-page'), previous, this.callbackFromInfinite);
    //if (!this.loading) proxyLoadNextPage(activeItem.current.getAttribute('data-pagination-page'), previous, this.callbackFromInfinite);
  };

  callbackFromInfinite = (moreToShow=true, previous) => {
    const activeItem = this.getCurrentItem();
    this.removeLightboxContent(activeItem.current);
    if (moreToShow) this.openLightbox(previous ? activeItem.previous : activeItem.next);
    document.getElementById('lightbox').classList.remove('js__lightbox--loading');
    this.setLoading(false)
  };

  getItemMetaData = () => {
    return {
      pageNumber : this.getCurrentItem().current.getAttribute('data-pagination-page'),
      catalogueNumber: this.getCurrentItem().current.getAttribute('data-catalogue-number')
    }
  };

  setLoading(val) {
    this.loading = val;
  }

  updateUrl = (metaData, removeQuickview) => {
    let obj = parse(window.location.search);
    if (removeQuickview) delete obj.quick_view;
    obj = removeQuickview ? {...obj, page: metaData.pageNumber} : {...obj, page: metaData.pageNumber, quick_view: metaData.catalogueNumber};
    const objStr = stringify(obj);
    return `${location.protocol}//${location.host}${location.pathname}?${objStr}`
  };
}




export const quickViewPreOpen = () => {
  return window.location.search.indexOf('quick_view') !== -1 ? parse(window.location.search).quick_view : false
};

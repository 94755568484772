export default class zoomImage {
    el
    originalImage
    image
    imageScale
    zoomEl
    photoEl
    constructor(el) {
        this.el = el
        this.originalImage = $('[data-image-zoom="original"]')
        this.image = el.getAttribute('data-image');
        this.imageScale = el.getAttribute('data-scale');
        this.hasImageLoaded( document.querySelector('[data-image-zoom="original"]')).then(()=> {
            const withTouch = this.isTouchDevice()
            withTouch ? this.initWithTouch() : this.initNoTouch()
        }).catch(err=> {
            console.log(err)
        })
    }
    initNoTouch = () => {
        this.el.classList.add('js__zoomInit')
        $(this.el).prepend(this.renderPhoto());

        const zoomEl = document.querySelector('.zoomImage__photo');
        const zoomImgEl = zoomEl.querySelector('.zoomImage__photo__image');

        this.onResize($(zoomEl))

        $(zoomEl).on('mouseover', ()=> {

            this.onResize($(zoomEl));
            this.doZoom(zoomEl,zoomImgEl);
        })
        $(zoomEl).on('mouseout', ()=> {

            // this.doZoom(el,imgEl);
        })
        window.addEventListener("resize", ()=> {
            this.onResize($(zoomEl))
        })
    }

    initWithTouch = () => {
        const touchClass = 'js__zoomInit__touch'
        const showNavClass = 'js__fullscreenZoom__showNav';
        const initClass = 'js__fullscreenZoom__init'

        this.el.classList.add(touchClass)
        // $(el).attr('style','')
        $(this.el).prepend('<button class="btn btn--primary btn--noBorder btn--search zoomImage__open">Zoom image</button>')
        $(this.el).on('click', ()=> $(this.el).toggleClass(showNavClass))

        $('.zoomImage__open').on('click', ()=> {
            $(this.el).addClass(initClass);
                $('#zoomImage').css({minHeight: `${window.innerHeight+100}px`, minWidth:`${window.innerWidth+100}px`})
                $(this.el).prepend('<button class="btn btn--primary  btn--hiddenText btn--noBorder btn--close btn--large zoomImage__close">Close</button>')
                $('.zoomImage__close').on('click', ()=> {
                    $(this.el).removeClass(initClass);
                    $(this.el).removeClass(showNavClass)
                    $('.zoomImage__close').remove();
                    $('#zoomImage').css({minHeight: `auto`, minWidth:`auto`})
                })
        })
    }

    doZoom = (zoomEl,zoomImgEl) => {
        $(zoomImgEl).css('transform',`scale(${this.imageScale})`)
        zoomImgEl.addEventListener('mousemove', (event) => {
            const  a = zoomEl.getBoundingClientRect();
            let x = event.pageX - a.left
            let y = event.pageY - a.top
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            const xAxisMove = x / zoomEl.offsetWidth;
            const yAxisMove = y / zoomEl.offsetHeight;
            $(zoomImgEl).css('transform-origin',`${xAxisMove * 100}% ${yAxisMove * 100}%`)
        })
    }



    renderPhoto = () => {
        return `<div class="zoomImage__photo" style="overflow:hidden; position:absolute;top:50%;left:50%;"><div class="zoomImage__photo__image" style="background-image:url(${this.image})"></div></div>`
    }

    onResize = (image) => {
        image.css({
            width:`${this.originalImage.width()}px`,
            height:`${this.originalImage.height()}px`,
            marginTop:`-${this.originalImage.height()/2}px`,
            marginLeft:`-${this.originalImage.width()/2}px`
        })
    }

    hasImageLoaded = (image) => {
       return new Promise((resolve, reject)=>{
           let timer = 0;
           const isComplete = (image) => {
               if (image.complete && image.naturalHeight !== 0) {
                   resolve()
               }
               else {
                   setTimeout(()=> {
                       if (timer < 20) {
                           timer++
                           isComplete(image)
                       }
                       else {
                           reject('image load failed')
                       }
                   }, 300)
               }
           }
           isComplete(image)
        })

    }
    isTouchDevice = () => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    }
}
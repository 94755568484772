import {map} from 'lodash';
import inViewport from '../helpers/inViewport';
import imagesLoaded from 'imagesloaded';

const imagePicker = (overrideImage = false, overrideWidth = false, infiniteScrollReload=false) => {
    const imageList = infiniteScrollReload ? document.querySelectorAll('.image--lazy:not(.image--lazy--loaded)') : document.querySelectorAll('.image--lazy');
    const images = overrideImage ? overrideImage : imageList;
    if (overrideImage.length > 0 && overrideImage[0].classList.contains('image--lazy--loaded')) overrideImage[0].classList.remove('image--lazy--loaded');
    _.map(images, (item)=> {
        if (!item.classList.contains('image--lazy--loaded') && inViewport(item,400)) {
            let outerWidth = item.offsetWidth;
            const image = item.getElementsByTagName('img')[0];
            let selectedAttribute = false;
            let dataCurrent = false;
            _.map(image.attributes, attribute => {
                let dataWidth = attribute.name.toString().split('data-img-')[1];
                outerWidth = overrideWidth ? overrideWidth : outerWidth;
                if  (attribute.name.indexOf('data-img') !== -1
                    && dataWidth > outerWidth
                    && !selectedAttribute) {
                    dataCurrent = dataWidth;
                    selectedAttribute = attribute;
                }
            });
            image.setAttribute('src', selectedAttribute.value);
            image.setAttribute('data-current', dataCurrent.toString());
            imagesLoaded(item, ()=> {
                item.classList.add('image--lazy--loaded');
            });

        }
    })
};

export default imagePicker
